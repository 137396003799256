export default [
    {
        label: 'No.',
        field: 'orderNo',
        sort: true
    },
    {
        label: '비행장소',
        field: 'airfield',
        sort: false
    },
    {
        label: '',
        field: 'action',
        sort: false
    }
];

<template>
  <div v-show="isProgress">
    <div class="modal-cover"></div>
    <!-- <img src="@/assets/progress.gif" alt="progress" style="width: 30px" class="progress-icon"> -->
    <img src="@/assets/progress.gif" alt="progress" style="width: 50px" class="progress-icon">     
  </div>
</template>

<script>
export default {
  name: "Progress",
  props: {
    isProgress: Boolean,
  },
}
</script>

<style scoped>

</style>

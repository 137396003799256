export default [
    {
        label: 'No.',
        field: 'orderNo',
        sort: true
    },
    {
        label: '이름',
        field: 'name',
        sort: false
    },
    {
        label: '자격번호',
        field: 'no',
        sort: false
    },
    {
        label: '서명',
        field: 'signature',
        sort: false
    },
    {
        label: '',
        field: 'action',
        sort: false
    }
];
